import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('div', {
    staticClass: "mapAllianz"
  }, [_c('ul', {
    staticClass: "mapAllianzUl condensed map-scroller"
  }, [_c('li', [_c('v-checkbox', {
    attrs: {
      "label": "Inkl. Status Z1"
    },
    model: {
      value: _vm.isZ1Inclusive,
      callback: function callback($$v) {
        _vm.isZ1Inclusive = $$v;
      },
      expression: "isZ1Inclusive"
    }
  }), _c('br'), _c('v-checkbox', {
    attrs: {
      "label": "Inkl. Status T1"
    },
    model: {
      value: _vm.isT1Inclusive,
      callback: function callback($$v) {
        _vm.isT1Inclusive = $$v;
      },
      expression: "isT1Inclusive"
    }
  }), _c('br'), _c('span', [_vm._v("Filtern nach Sv:")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filteredSv,
      expression: "filteredSv"
    }],
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.filteredSv = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    domProps: {
      "value": ''
    }
  }, [_vm._v("- alle Svs -")]), _vm._l(_vm._svNamesFB, function (sv, idx) {
    return _c('option', {
      domProps: {
        "value": idx
      }
    }, [_vm._v(_vm._s(sv.name))]);
  })], 2)], 1), _vm._m(0), _vm._l(_vm.filteredFBProjectsnoCompleted, function (item, uid) {
    return _c('li', {
      key: item.id,
      class: {
        loc: (item.loc || {}).lat,
        _active: _vm.scrollToAkt == item.id
      },
      on: {
        "click": function click($event) {
          return _vm.selectAkt(item);
        }
      }
    }, [_c('div', {
      staticClass: "fl bold"
    }, [_vm._v(_vm._s(item.vorname) + " " + _vm._s(item.name))]), _c('div', {
      staticClass: "fr color-lightgrey"
    }, [_c('small', [_vm._v(_vm._s(item.resolvedsv))])]), _c('div', {
      staticClass: "inl soft"
    }, [_vm._v(_vm._s(item.plz_risiko) + " " + _vm._s(item.ortschaft_risiko) + " " + _vm._s(item.adresse_risiko) + " " + _vm._s(item.adresse_risiko_zusatz))])]);
  })], 2), _c('div', {
    staticClass: "map"
  }, [_c('gmap-map', {
    attrs: {
      "id": "svmap",
      "center": _vm.mapdata.maincenter,
      "zoom": _vm.mapdata.zoom
    }
  }, [_vm.isMapVisible || _vm.countProjects < 500 ? _c('span', _vm._l(_vm.mapMarkerList, function (akt, aix) {
    return _c('gmap-marker', {
      key: aix,
      attrs: {
        "icon": _vm.icon,
        "position": akt.loc
      },
      on: {
        "click": function click($event) {
          return _vm.selectMarker(akt);
        }
      }
    });
  }), 1) : _vm._e(), _c('gmap-info-window', {
    attrs: {
      "options": {},
      "position": _vm.infoWinPos,
      "opened": _vm.infoWinOpen
    },
    on: {
      "closeclick": function closeclick($event) {
        _vm.infoWinOpen = false;
      }
    }
  }, [_c('router-link', {
    staticClass: "link bold",
    attrs: {
      "to": '/allianz/list/' + _vm.infoWinContent.id
    }
  }, [_vm._v(_vm._s(_vm.infoWinContent.polnr || _vm.infoWinContent.id))]), _c('p', [_c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s(_vm.getSvInfo(_vm.infoWinContent)))]), _c('br'), _c('span', {
    staticClass: "bold"
  }, [_vm._v(_vm._s((_vm._statusObj[_vm.infoWinContent.status] || {}).icon) + " - " + _vm._s((_vm._statusObj[_vm.infoWinContent.status] || {}).name))]), _c('br'), _c('br'), _c('span', {
    staticClass: "underline"
  }, [_vm._v("VN: " + _vm._s(_vm.infoWinContent.vorname + ' ' + _vm.infoWinContent.name))]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.infoWinContent.adresse_risiko + ' ' + _vm.infoWinContent.adresse_risiko_zusatz))]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.infoWinContent.plz_risiko + ' ' + _vm.infoWinContent.ortschaft_risiko))])])], 1)], 1), _vm.countProjects > 500 ? _c('div', {
    staticClass: "warner shadow"
  }, [_c('div', {
    staticClass: "padding _small tac"
  }, [_c('div', {
    staticClass: "color-red bold"
  }, [_vm._v("Bitte beachten dass zu viele Akte etwas länger zum Anzeigen brauchen (" + _vm._s(_vm.countProjects) + ") Dies kann in den Filtern begrenzt werden!")]), _c('div', {
    staticClass: "btn _small",
    on: {
      "click": function click($event) {
        _vm.isMapVisible = !_vm.isMapVisible;
      }
    }
  }, [!_vm.isMapVisible ? _c('span', [_vm._v("anzeigen")]) : _vm._e(), _vm.isMapVisible ? _c('span', [_vm._v("ausblenden")]) : _vm._e()])])]) : _vm._e()], 1)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('li', [_c('div', {
    staticClass: "padding tac"
  }, [_c('div', {
    staticClass: "color-red"
  }, [_vm._v("In den Spalten Filtern können die anzeigten Akte angepasst werden!")])])]);
}];
export { render, staticRenderFns };